import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase-config';
import { collection, query, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from '@chakra-ui/react';

function History() {
  const [user] = useAuthState(auth);

  const historyRef = collection(db, 'users/' + user.uid + '/history');
  // console.log(user.uid);

  const [values, loading, error] = useCollectionData(
    query(historyRef, where('cost', '!=', null))
  );

  useEffect(() => {
    console.log(values);
  }, [values]);

  if (!values) {
    return <Heading>There's nothing here!</Heading>;
  } else {
    return (
      <Accordion bgColor="white" width="100%">
        {values.map((item, index) => {
          if (item.output.content)
            return (
              <AccordionItem width="100%">
                <AccordionButton width="100%">
                  <Box
                    flexGrow={1}
                    as="span"
                    flex="1"
                    textAlign="left"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <b>{item.output.content}...</b>
                  </Box>
                  <Box as="span" flex="1" textAlign="right">
                    {item.cost}⚡
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>{item.output.content}</AccordionPanel>
              </AccordionItem>
            );
        })}
      </Accordion>
    );
  }
}

export default History;
