import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, HStack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { functions } from '../../firebase-config';
import { useHttpsCallable } from 'react-firebase-hooks/functions';

function ParagraphTemplate({
  addParagraph,
  type,
  header,
  promptPrefix,
  placeholder,
  buttonText,
  switchMode,
  alternateMode,
}) {
  const [text, setText] = useState('');
  const ref = useRef(null);
  const [selected, setSelected] = useState(false);
  const [h, setH] = useState('24px');
  const [executeCallable, executing, error] = useHttpsCallable(
    functions,
    'genRequest'
  );

  async function handleSubmit() {
    const prompt = `${promptPrefix}${text}
      
      Paragraph:`;
    console.log('Prompt: ', prompt);
    console.log('Generating request');
    executeCallable({
      prompt: prompt,
      cost: 2000,
      type: 'bodypg',
    }).then(res => {
      if (res.data !== 'fail') {
        addParagraph({
          type: type,
          body: res.data.content.trim(),
          prompt: prompt,
        });
      } else {
        alert(
          'Sorry, we ran into a problem. Contact support if this error persists.'
        );
      }
    });
  }

  useEffect(() => {
    let height = ref.current.style.height;
    let num = Number(height.substring(0, height.length - 2));
    num += 16;
    setH(num + 'px');
  }, [ref, text]);

  return (
    <Box>
      <HStack w="100%" mt="4" mb="4">
        <div
          style={{ width: 2, height: h, backgroundColor: 'grey' }}
          className={
            selected
              ? 'animate__animated animate__fadeIn'
              : 'animate__animated animate__fadeOut'
          }
        />
        <div style={{ width: '100%' }}>
          <span style={{ height: '8px', fontWeight: 800, fontSize: 10 }}>
            {header}
          </span>
          <ReactTextareaAutosize
            ref={ref}
            placeholder={placeholder}
            onFocus={() => {
              setSelected(true);
            }}
            onBlur={() => {
              setSelected(false);
            }}
            value={text}
            onChange={e => {
              if (!executing) {
                setText(e.target.value);
              }
            }}
            rows={1}
            style={{
              width: '100%',
              resize: 'none',
              outline: 'none',
              overflow: 'hidden',
            }}
          />
        </div>
      </HStack>
      <Button
        isLoading={executing}
        onClick={() => {
          handleSubmit();
        }}
      >
        {buttonText}
      </Button>
      <Button
        onClick={() => {
          switchMode();
        }}
        variant="outline"
        colorScheme={'gray'}
        ml={2}
      >
        Switch to {alternateMode}
      </Button>
    </Box>
  );
}

export default ParagraphTemplate;
