import {
  Box,
  Button,
  Center,
  Heading,
  Input,
  Progress,
  Text,
  VStack,
  Spinner,
  HStack,
} from '@chakra-ui/react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useParams } from 'react-router-dom';
import { auth, db } from '../../firebase-config';
import AddParagraph from './AddParagraph';
import IntroParagraph from './IntroParagraph';
import Paragraph from './Paragraph';
import TextareaAutosize from 'react-textarea-autosize';

const LOADING_TEXT = [
  'Avocados are a fruit, not a vegetable.',
  'Trypophobia is the fear of closely-packed holes.',
  'Australia is wider than the moon.',
  'The unicorn is the national animal of Scotland.',
  'The Easter Island heads have bodies.',
  'Pigeons can tell the difference between Picasso and Monet.',
  'Ketchup was once sold as medicine.',
];

function Index() {
  const [loading, setLoading] = useState(true);
  const [paragraphs, setParagraphs] = useState([]);
  const [title, setTitle] = useState('');
  const [saving, setSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState('');
  const [user] = useAuthState(auth);
  let { docId } = useParams();

  useEffect(() => {
    setLastSaved('');
    setParagraphs([]);
    setTitle('');
    setSaving(false);
    setLoading(true);
    getDoc(doc(db, 'users', user.uid, 'docs', docId)).then(doc => {
      if (doc.data()) {
        if (doc.data().title) {
          setTitle(doc.data().title);
        }
        if (doc.data().paragraphs) {
          setParagraphs(doc.data().paragraphs);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [docId]);

  async function saveDocument() {
    setSaving(true);
    await setDoc(doc(db, 'users', user.uid, 'docs', docId), {
      paragraphs,
      title,
      updated: new Date(),
    });
    setLastSaved('last saved: ' + formatAMPM(new Date()));
    setSaving(false);
  }

  function updateText(index, content) {
    let arr = [...paragraphs];
    arr[index].body = content;
    setParagraphs([...arr]);
  }

  function deleteParagraph(index) {
    let arr = [...paragraphs];
    arr.splice(index, 1);
    setParagraphs([...arr]);
  }

  function addParagraph(obj) {
    console.log('Adding paragraph: ', obj);
    let arr = [...paragraphs];
    arr.push(obj);
    setParagraphs([...arr]);
    saveDocument();
  }
  if (!loading) {
    return (
      <Box
        pt="16"
        pl="16"
        pr="16"
        w="100%"
        className="animate__animated animate__fadeIn"
      >
        <Box
          w="100%"
          display="flex"
          flexDir="horizontal"
          justifyContent="space-between"
        >
          <Heading width="100%">
            <TextareaAutosize
              onBlur={() => {
                saveDocument();
              }}
              _focusVisible={{}}
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder="Untitled document"
              style={{
                outline: 'none',
                border: 'none',
                paddingLeft: 10,
                paddingRight: 0,
                fontWeight: '600',
                fontSize: 32,
                overflowWrap: 'break-word',
                resize: 'none',
                width: '70%',
              }}
            />
          </Heading>
          <HStack style={{ alignItems: 'center', width: '30%' }}>
            {saving ? <Spinner size="sm" /> : <></>}
            <Text fontSize="xs" color="gray">
              {lastSaved}
            </Text>
          </HStack>
        </Box>

        {paragraphs.map((item, index) => {
          return (
            <Paragraph
              onBlur={() => {
                saveDocument();
              }}
              key={index}
              type={item.type}
              text={item.body}
              del={() => {
                deleteParagraph(index);
              }}
              prompt={item.prompt}
              updateText={content => {
                updateText(index, content);
              }}
            />
          );
        })}
        {paragraphs.length === 0 ? (
          <IntroParagraph addParagraph={addParagraph} />
        ) : (
          <AddParagraph
            introContent={paragraphs[0] ? paragraphs[0].body : ''}
            addParagraph={addParagraph}
          />
        )}
      </Box>
    );
  } else
    return (
      <Box w="100%" h="95vh" overflow={'hidden'}>
        <Center h={'100%'}>
          <VStack flex={1}>
            <p>Here's a fact while you wait...</p>
            <Heading size={'md'} mb={8}>
              {
                LOADING_TEXT[
                  Math.floor(Math.random() * (LOADING_TEXT.length - 0 + 1)) + 0
                ]
              }
            </Heading>
            <Progress
              mt={8}
              colorScheme="gray"
              w="30%"
              size="xs"
              isIndeterminate
            />
          </VStack>
        </Center>
      </Box>
    );
}

export default Index;

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}
