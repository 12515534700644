import React from 'react';
import '@fontsource/noto-serif';
import '@fontsource/noto-sans';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import theme from './theme';
import NavigationBar from './pages/NavigationBar';
import { Route, Routes } from 'react-router';
import Login from './pages/Login/Login';
import EssayWriter from './pages/tools/EssayWriter/EssayWriter';
import { auth } from './firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import Signup from './pages/Signup/Signup';
import ReadingSummarizer from './pages/tools/ReadingSummarizer';
import Rephraser from './pages/tools/Rephraser';
import BuyCredits from './pages/BuyCredits';
import History from './pages/History';
import Composer from './pages/Composer';

function App() {
  const [user, loading, error] = useAuthState(auth);

  return (
    <ChakraProvider theme={theme}>
      <Flex h="100vh" w="100vw" dir="horizontal">
        <NavigationBar>
          <Routes>
            {user && user.uid ? (
              <>
                <Route path="/composer/:docId" element={<Composer />} />
                <Route path="/account" element={<Login />} />
                <Route path="*" element={<Login />} />
              </>
            ) : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="*" element={<Login />} />
              </>
            )}
          </Routes>
        </NavigationBar>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
