import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import ParagraphTemplate from './ParagraphTemplate';

function AddParagraph({ addParagraph, introContent }) {
  const [showButtons, setShowButtons] = useState(false);
  const [mode, setMode] = useState('BODY');

  if (showButtons) {
    return (
      <>
        <HStack mt="4">
          <Button>Add Body Paragraph</Button>
          <Button ml="4">Add Conclusion Paragraph</Button>
        </HStack>
      </>
    );
  } else if (mode === 'BODY') {
    return (
      <ParagraphTemplate
        buttonText="Generate Body Paragraph"
        placeholder={`How cars have contributed to global warming
- cite the department of transportation
- mention electric cars`}
        header="BODY PARAGRAPH TOPIC"
        type="BODY"
        addParagraph={addParagraph}
        switchMode={() => {
          setMode('CONCLUSION');
        }}
        alternateMode="Conclusion"
        promptPrefix={`Complete the academic essay with verbose, eloquent, academic, analytical tone.
        Intro Paragraph: ${introContent}
        
        Body Paragraph Topic:`}
      />
    );
  } else if (mode === 'CONCLUSION') {
    return (
      <ParagraphTemplate
        buttonText="Generate Conclusion Paragraph"
        placeholder={`conclude by reflecting on main points in intro paragraph`}
        header="CONCLUSION PARAGRAPH NOTES"
        type="CONCLUSION"
        addParagraph={addParagraph}
        switchMode={() => {
          setMode('BODY');
        }}
        alternateMode="Body"
        promptPrefix={`Complete the academic essay with verbose, eloquent, academic, analytical tone.
        Intro Paragraph: ${introContent}
        
        Conclusion Paragraph Notes:`}
      />
    );
  }
}
export default AddParagraph;
