import React, { useEffect, useRef, useState } from 'react';
import 'animate.css';
import { Box, HStack, Icon, VStack, Tooltip } from '@chakra-ui/react';
import { CloseIcon, CopyIcon, RepeatIcon } from '@chakra-ui/icons';
import TextareaAutosize from 'react-textarea-autosize';
import { functions } from '../../firebase-config';
import { useHttpsCallable } from 'react-firebase-hooks/functions';

function Paragraph({ updateText, text, del, type, prompt, onBlur }) {
  const ref = useRef(null);
  const [selected, setSelected] = useState(false);
  const [h, setH] = useState('24px');
  const [executeCallable, executing, error] = useHttpsCallable(
    functions,
    'genRequest'
  );

  async function refresh() {
    console.log('Prompt: ', prompt);
    console.log('Generating request');
    executeCallable({
      prompt: prompt,
      cost: 2000,
      type: 'bodypg',
    }).then(res => {
      console.log(res);
      if (res.data !== 'fail') {
        console.log(res.data.content.trim());
        updateText(res.data.content.trim());
      } else {
        alert(
          'Sorry, we ran into a problem. Contact support if this error persists.'
        );
      }
    });
  }

  useEffect(() => {
    let num = Number(
      ref.current.style.height.substring(
        0,
        ref.current.style.height.indexOf('p')
      )
    );
    setH(num + 8 + 'px');
  }, [ref, text]);

  return (
    <HStack w="100%" mt="4" mb="4">
      <div
        style={{ width: 2, height: h, backgroundColor: 'grey' }}
        className={
          selected
            ? 'animate__animated animate__fadeIn'
            : 'animate__animated animate__fadeOut'
        }
      />
      <div style={{ width: '100%' }}>
        <span style={{ height: '8px', fontWeight: 800, fontSize: 10 }}>
          {type}
        </span>
        <TextareaAutosize
          ref={ref}
          placeholder="Begin this paragraph here..."
          onFocus={() => {
            setSelected(true);
          }}
          onBlur={() => {
            onBlur();
            setSelected(false);
          }}
          value={text}
          onChange={e => {
            updateText(e.target.value);
          }}
          rows={1}
          style={{
            width: '100%',
            resize: 'none',
            outline: 'none',
            overflow: 'hidden',
            fontSize: 18,
          }}
        />
      </div>
      <VStack>
        <Tooltip label={selected ? 'Delete' : ''}>
          <CloseIcon
            onClick={() => {
              del();
            }}
            style={{ marginBottom: 8 }}
            _hover={[selected ? { cursor: 'pointer' } : {}]}
            className={
              selected
                ? 'animate__animated animate__fadeIn'
                : 'animate__animated animate__fadeOut'
            }
            color="grey"
            fontSize="16"
          />
        </Tooltip>
        <Tooltip label={selected ? 'Copy' : ''}>
          <CopyIcon
            onClick={() => {
              navigator.clipboard.writeText(text);
            }}
            style={{ marginBottom: 8 }}
            _hover={[selected ? { cursor: 'pointer' } : {}]}
            className={
              selected
                ? 'animate__animated animate__fadeIn'
                : 'animate__animated animate__fadeOut'
            }
            color="grey"
            fontSize="16"
          />
        </Tooltip>
        <Tooltip label={selected ? 'Refresh' : ''}>
          <RepeatIcon
            onClick={() => {
              refresh();
            }}
            _hover={[selected ? { cursor: 'pointer' } : {}]}
            className={
              selected
                ? 'animate__animated animate__fadeIn'
                : 'animate__animated animate__fadeOut'
            }
            color="grey"
            fontSize="16"
          />
        </Tooltip>
      </VStack>
    </HStack>
  );
}

export default Paragraph;
