import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Heading,
  Button,
  Tag,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { HiOutlineDocument } from 'react-icons/hi';
import { FiMenu } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  query,
  collection,
  orderBy,
  getDocs,
  addDoc,
} from 'firebase/firestore';
import { app, auth, db } from '../../firebase-config';
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth';

export default function SidebarWithHeader({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={'white'}>
      <SidebarContent
        onClose={() => onClose}
        visibility={{ base: 'hidden', md: 'visible' }}
        width={{ base: '0', md: '300px' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav is the button to open the mobile fullscreen navigation and the fullscren nav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Flex w={{ base: '100vw', md: 'calc(100vw - 17rem)' }}>{children}</Flex>
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [signOut] = useSignOut(auth);

  useEffect(() => {
    updateList();
    if (!user) {
      setPages([]);
    }
  }, [user, updateList]);

  function newDoc() {
    addDoc(collection(db, 'users', user.uid, 'docs'), {
      title: '',
      updated: new Date(),
      paragraphs: [],
    })
      .then(doc => {
        updateList();
        navigate('/composer/' + doc.id);
      })
      .catch(e => {
        alert('Error creating document.');
      });
  }

  function updateList() {
    if (user) {
      const q = query(
        collection(db, 'users', user.uid, 'docs'),
        orderBy('updated', 'desc')
      );
      getDocs(q).then(snap => {
        let arr = [];
        snap.forEach(doc => {
          arr.push({
            name: doc.data().title ? doc.data().title : 'Untitled document',
            link: '/composer/' + doc.id,
          });
        });
        if ((pages.length = 0 && arr.length !== 0)) {
          navigate(arr[0].link);
        }
        setPages([...arr]);
        setLoading(false);
      });
    }
  }

  return (
    <Box
      onClick={() => {
        updateList();
      }}
      transition="3s ease"
      bg={'#fafaf9'}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="left">
        {/* <Image src="../Logo.png" style={{ height: 60, width: 60 }} /> */}
        <Heading fontSize="lg" fontWeight="bold">
          ProScribe <Tag>BETA</Tag>
        </Heading>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {user ? (
        <Button
          className="animate__animated animate__fadeInUp"
          onClick={() => {
            newDoc();
          }}
          _hover={{ backgroundColor: '#34495e' }}
          align="center"
          p="4"
          mx="4"
          w="calc(100% - 32px)"
          bgColor="#2c3e50"
          color="white"
        >
          New Document
        </Button>
      ) : (
        <></>
      )}
      {loading && user ? (
        <Box w="100%" mt={4}>
          <Center>
            <Spinner />
          </Center>
        </Box>
      ) : (
        <></>
      )}
      {pages.map(link => (
        <NavItem
          className="animate__animated animate__fadeInUp"
          key={link.link}
          icon={HiOutlineDocument}
          link={link.link}
        >
          <Heading size="xs" textColor="#2c3e50">
            {link.name}
          </Heading>
        </NavItem>
      ))}
      <Box flexGrow="1" />
      {user ? (
        <Button
          className="animate__animated animate__fadeInUp"
          p="4"
          mx="4"
          mb="4"
          w="calc(100% - 32px)"
          onClick={() => {
            signOut().then(() => {
              setPages([]);
            });
          }}
        >
          Log Out
        </Button>
      ) : (
        <></>
      )}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  link: String;
}
const NavItem = ({ icon, link, children, ...rest }: NavItemProps) => {
  return (
    <Link
      to={link}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        my="1"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        borderWidth="1px"
        borderColor="none"
        _hover={{
          borderColor: '#2c3e50',
        }}
        {...rest}
      >
        {icon && <Icon mr="4" fontSize="16" as={icon} color="#2c3e50" />}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={8}
      height={{ base: 20, md: 0 }}
      display={{ base: 'flex', md: 'none' }}
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={'flex-start'}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Heading fontSize="lg" fontWeight="bold" ml={4}>
        ProScribe <Tag>BETA</Tag>
      </Heading>
    </Flex>
  );
};
