import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Badge,
} from '@chakra-ui/react';
import { auth } from '../../firebase-config';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useState } from 'react';
import { Link, redirect } from 'react-router-dom';

export default function SimpleCard() {
  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleSubmit() {
    createUserWithEmailAndPassword(email, password).then(usr => {
      if (error) {
        console.log(error);
      }
      // eslint-disable-next-line no-restricted-globals
      if (usr) return redirect('/tools/essaywriter');
      console.log(usr);
    });
  }

  return (
    <Flex
      minH={'100vh'}
      minW="100%"
      align={'center'}
      justify={'center'}
      pb="200"
    >
      <Stack spacing={8} mx={'auto'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading
            fontSize={'4xl'}
            w={{ base: '18ch', md: '26ch' }}
            textAlign="center"
          >
            Welcome to ProScribe
          </Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            Already have an account?{' '}
            <Link to="/login" style={{ color: 'rgb(66,153,255)' }}>
              Log In
            </Link>{' '}
            ✌️
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            {error ? (
              <Badge colorScheme="red">
                Something wasn't quite right. Check your email and password.
              </Badge>
            ) : (
              <></>
            )}

            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'center'}
              >
                <Link color={'blue.400'}>Forgot password?</Link>
              </Stack>
              {loading ? (
                <Button
                  isLoading
                  loadingText="Submitting"
                  bg={'blue.400'}
                  color={'white'}
                  variant="outline"
                >
                  Submit
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  bg={'#2c3e50'}
                  color={'white'}
                  _hover={{
                    bg: '#34495e',
                  }}
                >
                  Sign Up
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
