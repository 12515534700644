import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAhRo8ARqu-siY1NvTJcyNw1WX2u8sHdUw',
  authDomain: 'gradegoat-7dac7.firebaseapp.com',
  projectId: 'gradegoat-7dac7',
  storageBucket: 'gradegoat-7dac7.appspot.com',
  messagingSenderId: '527118843453',
  appId: '1:527118843453:web:b77025451731812368b454',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);

export const functions = getFunctions(app);
// connectFunctionsEmulator(functions, '127.0.0.1', 5001);
